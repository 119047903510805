export enum ActionType {
  DUMMY_ACTION_0 = 'DUMMY_ACTION_0',
  DUMMY_ACTION_1 = 'DUMMY_ACTION_1',
  FETCH_WEATHER_START = 'FETCH_WEATHER_START',
  FETCH_WEATHER_COMPLETE = 'FETCH_WEATHER_COMPLETE',
  FETCH_WEATHER_FAIL = 'FETCH_WEATHER_FAIL',
  CHANGE_SETTINGS_START = 'CHANGE_SETTINGS_START',
  CHANGE_SETTINGS_COMPLETE = 'CHANGE_SETTINGS_COMPLETE',
  CHANGE_SETTINGS_FAIL = 'CHANGE_SETTINGS_FAIL',
  GET_CITY_WOEID_START = 'GET_CITY_WOEID_START',
  GET_CITY_WOEID_COMPLETE = 'GET_CITY_WOEID_COMPLETE',
  GET_CITY_WOEID_FAIL = 'GET_CITY_WOEID_FAIL',
  SET_CITY = 'SET_CITY',
  TOGGLE_TEMPERATURE_UNIT = 'TOGGLE_TEMPERATURE_UNIT',
}
